.container {
	width: 100%;
	height: 96vh;
	// background: #ebebeb;
	// background: rgb(35, 39, 65);
	background: url(https://b2c-themes.obs.cn-north-4.myhuaweicloud.com/abb_logo_bj.png) no-repeat center;
	background-size: cover;
	.bj_opacity{
		width: 100%;
		height: 96vh;
		background-color: rgba(255,255,255,0.8);
	}
}
.title1 {
	text-align: center;
	color: #333;
	font-size: 24px;
	font-family: ABBvoiceCNSG-Regular;
	font-weight: 400;
	line-height: 19px;
	padding-bottom: 20px;
	// margin-bottom: 25px;
}
.content1 {
	width: 540px;
	//height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	// background: rgba(255,255,255,1);
	padding: 40px;
	padding-bottom: 0;
	color: #333;
	// border-radius: 16px;
	.content_text{
		text-align: center;
		div{
			line-height: 30px;
			font-size: 20px;
		}
	}
	.content_icon{
		height: 48px;
		// background-color: pink;
		width: 80%;
		margin: 20px auto;
		img{
			width: 100%;
			height: 100%;
		}
	}
}
.logo1{
	text-align: center;
	padding-bottom: 46px;
}
.guide{
	
	margin-left: 285px;
	font-size: 14px;
	color: #333;
	border-bottom: 1px solid #333;
}
.broadcast{
	width: 180px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	margin-top: 40px;
}
.advertising{
	
	// margin-left: 285px;
	font-size: 14px;
	color: #333;
	border-bottom: 1px solid #333;
}
.scancode{
	width: 280px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	margin-top: 40px;
}
.button1{
	cursor: pointer;
	text-align: center;
	display: block;
	margin: 16px auto;
	width: 280px;
	height: 40px;
	font-size: 14px;
	line-height: 40px;
	font-family: ABBvoiceCNSG-Regular;
	font-weight: 400;
	color: rgba(255,255,255,1);
	background: rgba(255,0,15,1);
	border-radius: 4px;
	
}
.button2{
	cursor: pointer;
	text-align: center;
	display: block;
	margin: 16px auto;
	width: 280px;
	height: 40px;
	font-size: 14px;
	line-height: 40px;
	font-family: ABBvoiceCNSG-Regular;
	font-weight: 400;
	color: rgba(255,0,15,1);
	background: #fff;
	border-radius: 4px;
	border: 1px solid rgba(255,0,15,1);
	
}
.otherLogin-box{
	text-align: center;
	.otherLogin_button_box{
		width: 80px;
		// background-color: pink;
		margin: auto;
		display: flex;
		justify-content: space-between;
		.otherLogin_button{
			cursor: pointer;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: url("../svg/myabb.svg") no-repeat center;
			background-size: auto;
		}
		.otherLogin_button_weixin{
			background: url("../svg/weixin.svg") no-repeat center;
			background-size: auto;
		}
	}
}
.button1:hover{
	background-color: #CC0815 !important;
	color: #fff !important;
}
.button1:active{
	background-color: #A30005 !important;
	color: #fff !important;
}
@font-face {
    font-family: 'ABBvoiceCNSG-Regular';
	// src: url('../fonts/ABBvoice_CNSG_Rg.woff2') format("woff2"),
	// url('../fonts/ABBvoice_CNSG_Rg.woff') format("woff"),
	// url('../fonts/ABBvoice_CNSG_Rg.ttf') format("truetype"),
	// url('../fonts/ABBvoice_CNSG_Rg.eot') format("embedded-opentype"),
	// url('../fonts/ABBvoice_CNSG_Rg.svg') format("svg"),
	// url('../fonts/ABBvoice_CNSG_Rg.otf') format("opentype");
}