.ant-menu-inline {
	border: none;
}

.search-form {
	padding: 15px;

	.ant-form-item {
		display: flex;
	}

	.ant-form-item-control-wrapper {
		flex: 1;
	}
}

.serarch-btns {
	display: flex;

	.ant-form-item {
		margin-right: 10px;
	}
}

// 整体背景色
.ant-layout {
	min-width: 1171px;
	background: #ebebeb;
}

.ant-menu-horizontal {
	border: none;
}

// 首页导航条颜色
.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected {
	color: #1F1F1F;
	border-bottom: 2px solid #3366FF;
}

.ant-menu-horizontal>.ant-menu-item {
	width: 171px;
	text-align: center;
}

.ant-menu-item-active {
	// background-color: #EBEBEB !important;
	// border-bottom: 2px solid #EBEBEB !important;
	// color: #1F1F1F !important;
}

.ant-modal-header {
	border-top: 4px solid #1F1F1F;
	border-radius: 0;
	border-bottom: 0;
	padding: 16px 33px;
}

.ant-modal-title {
	margin-top: 9px;
	font-size: 16px;
	color: #1F1F1F;
	font-weight: 600;
}

.ant-modal-content {
	border-radius: 0;
}

.ant-modal-close {
	color: #1F1F1F;
	font-size: 20px;
}

.ant-modal-body {
	padding: 0 32px 28px 32px;
}

.ant-modal-footer {
	border: none;
	padding-top: 0;
	padding-right: 32px;
	padding-bottom: 8px;
}

.ant-modal-mask {
	background: #000000;
	opacity: 0.2;
	animation: normal;
}

.ant-table-thead>tr>th .ant-table-filter-icon {
	position: absolute;
	top: 2px;
	/* right: 20px; */
	left: 55px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
	height: 32px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
	border-radius: 0;
	height: 32px;
	line-height: 32px;
	width: 128px;
	font-weight: 500;
	text-align: center;
	background: #DBDBDB;
	color: #1F1F1F;
	margin-right: 8px;
	border: none;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
	height: 32px;
	// border-top: 4px #3366FF solid;
	background: #fff;
	// color: #3366FF;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
	content: '';
	height: 3px;
	display: block;
	width: 100%;
	position: absolute;
	top: 0px;
	border-radius: 0;
	border-top: 2px #3366FF solid;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover {
	background: #BABABA;
}

.ant-dropdown-menu-item {
	color: #1F1F1F;
}

.ant-dropdown-menu-item:hover {
	background-color: #EBEBEB;
}

.ant-table-small>.ant-table-content>.ant-table-body {
	margin: 0;
}

.ant-tabs-bar {
	margin: 0;
}

.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
	background-color: #fff;
	min-height: 85vh;
	padding: 16px;
}

.ant-table-content {
	background-color: #fff;
	overflow-x: auto;
}

.ant-btn:hover {
	// border: 1px solid #9F9F9F;
	color: #1F1F1F;
}

.ant-btn-primary {
	height: 40px;
	background: #3366FF;
	border-color: #3366FF;
}

.ant-btn-primary:hover {
	background: #2A35FF;
	border-color: #2A35FF;
	color: #fff;
}

.ant-btn-primary:active {
	background: #2C20D4;
	border-color: #2C20D4;
}

.ant-input {
	border: none;
	border-bottom: 1px solid #BABABA !important;
	border-radius: 0;
	color: #1F1F1F !important;
	font-size: 14px;
	line-height: 16px;
	font-family: ABBvoiceCNSG-Regular;

}

.ant-input-affix-wrapper input {
	background-color: rgba(217, 217, 217, 0);
}

//搜索框相关
.ant-input-affix-wrapper .ant-input:hover {

	border-bottom: 1px solid #9F9F9F !important;
}

.ant-input-affix-wrapper .ant-input:focus {
	border: none;
	border-bottom: 1px solid #3366FF !important;
	box-shadow: none;
}

.model_input .ant-input:hover {
	background-color: #EDEAED;
	// border: 1px solid #9F9F9F;
}

.model_input .ant-input:focus {
	border: 1px solid #36f;
}

.ant-table-thead>tr>th {
	border: none;
	font-family: ABBvoiceCNSG-Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	color: #686868;
	border-bottom: 1px solid #1F1F1F !important;
}

// .ant-table-tbody > tr > td {
// 	border-bottom: 1px solid #BABABA !important;
// }
.ant-table-tbody>tr>td {
	border-bottom: 1px solid #BABABA;
	color: #1F1F1F;
	padding: 9px 8px !important;
}

.ant-table-small {
	border: none;
	border-bottom: 1px solid #1F1F1F !important;
}

.ant-dropdown-menu {
	padding: 0;
}

.ant-menu {
	background: none !important;
	margin-top: 4px;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-submenu-title {
	height: 32px !important;
	line-height: 32px !important;
	margin: 0 !important;
}

.ant-menu-inline .ant-menu-item-selected::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	border-right: none;
}

.ant-menu-submenu-selected {
	color: #1F1F1F;
}

.ant-menu-inline .ant-menu-item-selected {
	background-color: #EBEBEB !important;
	// color: #1F1F1F;
	color: #3366FF;
	font-size: 14px;
	font-family: ABBvoiceCNSG-Medium;
}

.ant-menu-inline .ant-menu-item-selected::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	// border-right: 4px solid #3366FF;
}

.ant-btn {
	border: none;
}

//下拉框相关
.ant-select-selection {
	border-radius: 0em;
	border-color: #BABABA;
	font-size: 14px;
	color: #1F1F1F;
}

.ant-select-selection:hover {
	background: #EBEBEB;
	border-color: #9F9F9F;
}

.ant-select-selection:focus {
	border-color: #3366FF;
	box-shadow: none;
}

.ant-table-body::-webkit-scrollbar {
	width: 0px;
	height: 0;
	background: none;
}

.ant-table-tbody>tr:hover>td {
	background: #dbdbdb !important;
}

.ant-select-dropdown-menu {
	padding: 0 !important;
}

.ant-select-dropdown-menu-item-active {
	background-color: #EBEBEB !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
	margin: 0;
}

.ant-tooltip-open {
	z-index: 1000 !important;
}

.ant-form-item-label {
	height: 15px;
	line-height: 17px;
	text-align: left;
}

.addDeviceApplayType {
	margin-top: 9px;

	.ant-form-item-label label {
		color: #fff !important;
	}

}

.ant-form-item-required::before {
	display: inline-block;
	margin-right: 0px;
	color: #fff;
	font-size: 0;

	font-family: SimSun, sans-serif;
	line-height: 16px;
	content: '.';
}

.ant-menu-submenu-selected {
	color: #3366FF !important;
	// font-family: ABBvoiceCNSG-Medium;
	// background-color: pink;
}

.ant-menu-submenu-title_active {
	color: #3366FF;
	// background: red;
	font-family: ABBvoiceCNSG-Medium;
	// font-family: ABBvoiceCNSG-Medium;
	// font-weight: 500;
	// width: 204px;
	// position: absolute;
	// left: 0;
	// background-color: #DBDBDB !important;
	// color: #1F1F1F;
	// font-size: 14px;
	// padding-left: 40px;
}

.ant-menu-submenu-title_active::before {
	content: "";
	display: block;
	// position: absolute;
	// top: 0;
	// left: 0;
	// bottom: 0;
	// border-left: 4px solid #3366FF !important;
}

.ant-tooltip-inner {
	background-color: #fff;
	color: #1F1F1F;
	// height: 28px;
	border-radius: 0;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
	border: 1px solid rgba(186, 186, 186, 1);
}

.ant-tooltip {
	max-width: 500px;
}

.ant-tooltip-arrow::before {
	background-color: #bababa;
	width: 8px;
	height: 8px;
}

.ant-calendar .ant-calendar-ok-btn-disabled {
	color: #fff;
}

.ant-calendar-ok-btn {
	background: #3366FF !important;
	border: none !important;
	border-radius: 4px !important;
}

.ant-calendar .ant-calendar-ok-btn-disabled:hover {
	background: #2A35FF !important;
	color: #fff;
}

.ant-calendar-ok-btn:hover {
	background: #2A35FF !important;
	color: #fff;
}

.ant-calendar .ant-calendar-ok-btn-disabled:active {
	background: #2C20D4 !important;
	color: #fff;
}

.ant-calendar-ok-btn:active {
	background: #2C20D4 !important;
}

.ant-calendar-picker-container {
	font-family: ABBvoiceCNSG-Regular;
	z-index: 10000;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
	background-color: #3366FF;
}

.ant-calendar-date:hover {
	background-color: #DEF4FA;
}

.ant-calendar-time-picker-btn {
	color: #3366FF;
}

.ant-calendar-time-picker-btn:hover {
	color: #2A35FF !important;
}

.ant-calendar-time-picker-btn:active {
	color: #2C20D4 !important;
}

.ant-calendar-input {
	text-align: center;
}

.ant-calendar-selected-day .ant-calendar-date {
	background-color: #3366FF;
	color: #FFF;
	border: 1px solid #6CD5FF;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
	background-color: #6CD5FF;
}

.ant-calendar-range-picker-input {
	font-size: 14px;
	color: #1f1f1f;
}

.ant-calendar-range-right .ant-calendar-date-input-wrap {
	margin-left: 0;
}

.ant-calendar-range-middle {
	padding: 0;
}

.ant-form-explain {
	color: #686868 !important;
	line-height: 15px !important;
	// margin-top: 2px;
	font-size: 12px;
}

.ant-form-explain::before {
	display: inline-block;
	content: '×';
	height: 16px;
	text-align: center;
	font-family: ABBvoiceCNSG-Regular;
	width: 16px;
	color: #fff;
	font-size: 18px;
	background-color: #F03040;
	border-radius: 50%;
	margin-right: 8px;
}

.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
	color: #3366FF;
}

.has-error .ant-select-selection {
	border: 1px solid #BABABA;
	border-bottom: 2px solid #F03040;
}

.org_information_list,
.ant-table-body .ant-checkbox-checked .ant-checkbox-inner {
	// background-color: #36f;
	border: 1px solid #36f;
}

.org_information_list,
.ant-table-body .ant-checkbox-disabled .ant-checkbox-inner::after {
	border-color: #fff !important;
}

.ant-switch-checked {
	background-color: #3366ff !important;
}

.Abb_customerlist {
	.ant-table-body {
		height: 295px;
		overflow: auto;
	}
}

.ant-table-header-column .ant-checkbox-wrapper {
	margin-top: 4px;
}

.ant-collapse-borderless {
	background: #fff;

	.ant-collapse-item {
		border-bottom: none;
		background-color: #ebebeb;
		margin: 5px 0px;

		.ant-collapse-header {
			padding: 8px;
		}

		.ant-collapse-content {
			background: #fff;

			.ant-collapse-content-box {
				padding: 0;
			}
		}
	}
}

.gateway-box {
	display: flex;
	height: 60vh;
}

.gatewaymin {

	margin: auto;

	.ant-upload.ant-upload-drag {
		border: none;
		background: #fff;
	}
}
