.lifebox {
    margin: 0 16px 0px 0;
    // padding: 10px;
    // height:200px;
    // background: rgba(255, 255, 255, 1);
    // border:1px solid rgba(219,219,219,1);
    // border-radius:4px;
    min-width: 700px;
    overflow: auto;
    color: #1f1f1f;
    position: relative;
}
.lifestatus_title{
    font-size: 16px;
    margin: 12px;
    font-family: ABBvoiceCNSG-Regular;
    span{
        font-size: 12px;
    }
}
.lifebox_device{
    background-color: #fff;
    padding-top: 0px;
    margin-bottom: 8px;
}
.lifestatusbox{
    // background-color: #fff;
	display: flex;
    width: 100%;
    // justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    padding: 8px 10px;
    position: relative;
    min-height: 80px;
    align-items: center;
    margin-top: -23px;
    .lifestatus_list{
        width: 200px;
        display: flex;
        .lifestatus_icon{
            // width: 50px;
            // height: 50px;
            margin: 5px 10px 0 0px;
            .asset_type_icon{
                width: 25px;
                height: 25px;
                margin: 0 0 0 2px;
            }
            .asset_type_icon_green{
                background: url("../../../../assets/img/Condition1.png") no-repeat center;
                background-size: 100%;
            }
            .asset_type_icon_red{
                background: url("../../../../assets/img/Condition3.png") no-repeat center;
                background-size: 100%;
            }
            .asset_type_icon_gray{
                background: url("../../../../assets/img/Condition4.png") no-repeat center;
                background-size: 100%;
            }
            .asset_type_icon_yellow{
                background: url("../../../../assets/img/yellow_icon.png") no-repeat center;
                background-size: 100%;
            }
        }
        // padding-right: 8px;
        .lifestatus_right{
            .life_title{
                font-size: 16px;
                // margin-bottom: 3px;
            }
            .life_subtitle{
                font-size: 12px;
            }
        }
    }
}
.life_back{
    line-height: 18px;
    font-size: 18px;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #1F1F1F;
    margin-bottom: 8px;
    background-color: #fff;
    padding: 12px 8px;
    margin: 0 16px 0px 0;
}
.charts_box{
    // border-bottom: 2px solid #bababa;
    margin: 12px 0;
    padding: 12px 0;
}
.charts_flex{
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    min-height: 200px;
    // align-items: center;
    position: relative;
    .charts_flex_left_annuallife{
        width: 65%;
        height: 220px;
        flex: 1;
        padding-right: 4px;
    }
    .charts_flex_left_annualaging{
        width: 65%;
        height: 440px;
        flex: 1;

    }
    .charts_flex_left_dailylife{
        width: 65%;
        height: 180px;
        flex: 1;
    }
    .charts_flex_right{
        width: 250px;
        padding-top: 40px;
        color: #1f1f1f;
        
        >div{
            line-height: 32px;
            padding-left: 52px;
            display: flex;
            align-items: center;
        }
        
        .list_icon1::before{
            display: inline-block;
            content: "";
            width: 12px;
            height: 12px;
            background-color: #bababa;
            margin: 0px 8px 0 0;
        }
        .list_icon2::before{
            display: inline-block;
            content: "";
            width: 12px;
            height: 12px;
            background-color: #477a43;
            margin: 0px 8px 0 0;
        }
        .list_icon3::before{
            display: inline-block;
            content: "";
            width: 12px;
            height: 12px;
            background-color: #ffd800;
            margin: 0px 8px 0 0;
        }
        .list_icon4::before{
            display: inline-block;
            content: "";
            width: 12px;
            height: 12px;
            background-color: #f13040;
            margin: 0px 8px 0 0;
        }
        .list_icon5::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #ebebeb;
            background: url("../../../../assets/svg/icon_line_black.svg");
            background-size: 100%;
            margin: 0px 8px 0 0;
            // border-style: dotted;
        }
        .list_icon6::before{
            display: inline-block;
            content: "";
            width: 12px;
            height: 12px;
            background-color: #B3E6FF;
            margin: 0px 8px 0 0;
        }
        .list_icon7::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #0052FF;
            background-color: #0052FF;
            margin: 0px 8px 0 0;
            
        }
        .list_icon9::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #EBEBEB;
            background-color: #1f1f1f;
            margin: 0px 8px 0 0;
        }
        .list_icon10::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #B3E6FF;
            background: url("../../../../assets/svg/icon_line_black1.svg");
            background-size: 100%;
            margin: 0px 8px 0 0;
        }
        .list_icon8::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #B3E6FF;
            background: url("../../../../assets/svg/icon_line_gray1.svg");
            background-size: 100%;
            margin: 0px 8px 0 0;
        }
        .list_icon11::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #B3E6FF;
            background: url("../../../../assets/svg/icon_line_gray2.svg");
            background-size: 100%;
            margin: 0px 8px 0 0;
        }
        .list_icon12::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #B3E6FF;
            background: url("../../../../assets/svg/icon_line_gray3.svg");
            background-size: 100%;
            margin: 0px 8px 0 0;
        }
        .list_icon13::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 2px;
            font-size: 16px;
            color: #B3E6FF;
            background: url("../../../../assets/svg/icon_line_gray4.svg");
            background-size: 100%;
            margin: 0px 8px 0 0;
        }
        .list_icon14::before{
            display: inline-block;
            content: "";
            width: 15px;
            height: 3px;
            font-size: 16px;
            color: #B3E6FF;
            background: url("../../../../assets/svg/icon_line_black2.svg");
            background-size: 100%;
            margin: 0px 8px 0 0;
        }
    }
    .charts_flex_right_daily{
        color: #1F1F1F;
        padding-top: 0;
        .list_text{
            line-height: 22px;
        }
        .list_icon{
            font-size: 18px;
        }
    }
    
}
.lifesetting_text{
    font-size: 16px;
    color: #1f1f1f;
    margin: 4px 0;
}
.charts_button{
    position: absolute;
    top: 100px;
    right: 30px;
}