.neta21-title {
    // background-color: red;
    margin-bottom: 20px;
    font-size: 14px;
    color: #1f1f1f;
    border-bottom: 1px solid #BABABA;
    padding-bottom: 18px;

    >div {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        .neta21-icon {
            border: 1px solid #F03040;
            border-radius: 2px;
            color: #F03040;
            font-size: 12px;
            padding: 0 5px;
            margin-right: 8px;

        }

        .neta21-deive-icon {
            margin-right: 40px;
        }
    }

    .neta21-change-title {
        font-size: 18px;
        font-weight: 500;
    }
}

.nata21-lable {
    font-size: 14px;
    color: #1f1f1f;
    font-family: ABBvoiceCNSG-Bold;
    margin-bottom: 12px;
}

.table_header {
    font-size: 16px;
    height: 32px;
    display: flex;

    align-items: center;
    margin-bottom: 8px;

    .button_box {
        display: flex;
        background-color: #36f;
        height: 32px;
        width: 142px;
        color: white;

        font-size: 14px;
        line-height: 32px;
        border-radius: 4px;
        padding-left: 13px;
        align-items: center;

        .button_icon {
            margin-right: 11px;
        }
    }

    .right {
        margin-left: 40px;
    }
}