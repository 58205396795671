.remin_title {
    font-size: 20px;
    color: #1f1f1f;
    font-family: ABBvoiceCNSG-Bold;
}

.event_detail {
    font-size: 14px;
    color: #1f1f1f;
    margin-top: 28px;
    margin-bottom: 12px;
    font-family: ABBvoiceCNSG-Bold;
}

.message_wrapper {
    display: flex;
    font-size: 14px;
    margin-top: 3px;

    .left {
        color: #686868;
        width: 88px;
        min-width: 88px;
    }

    .right {
        color: #1f1f1f;
        display: flex;
        align-items: center;
    }
}

.close_remind {
    color: #1f1f1f;
    font-size: 14px;
    margin-left: 16px;
    display: flex;

    .time {
        color: #36f;
    }
}

.record_wrapper {
    margin-top: 24px;

    .record_header {
        color: #686868;
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .record_headline {
        width: 100%;
        height: 1px;
        margin-top: 5px;
        background-color: #1f1f1f;
    }
}

.record_item {
    height: 48px;
    border-bottom: 1px solid #bababa;
    line-height: 48px;
    color: #1f1f1f;
    font-size: 14px;
}

.group_section {

    display: flex;
    justify-content: space-between;
    margin-right: 28px;
    color: #1f1f1f;

    .left {
        font-size: 14px;
    }

    .right {
        font-size: 12px;
    }
}

.device_status_box {
    display: flex;
    align-items: center;
}

.ant-table-filter-dropdown {
    width: 146px;
}

.tablefiltersearch {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #36f;
        border-color: #36f;
    }
}

.button-filter {
    cursor: pointer;
    background: #36f;
    width: 65px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    margin-top: 16px;
    margin: 16px auto;
}

.button-filter:hover {
    background: #3366ff;
}

.grouptable {
    .ant-collapse-content-active {
        min-height: 320px;
    }
}