@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';
/* 路由过渡效果 */
.fade-enter {
	opacity: 0;
	transform: translateX(-30px);
}

.fade-enter-active,
.fade-exit-active {
	opacity: 1;
	transition: all 500ms ease-out;
	transform: translateX(0);
}

.fade-exit {
	opacity: 0;
	transform: translateX(30px);
}
$borderColor: #d8d8d8;
.app-sider {
	border-right: 1px solid $borderColor;
	overflow-y: auto;
	overflow-x: hidden;
}
body{
	font-family: ABBvoiceCNSG-Regular;
}
body::-webkit-scrollbar{
    width: 0px;
    height: 0px;
    background: none;
}
ul,li{ padding:0;margin:0;list-style:none}

// iframe{
// 	position: static !important;
// 	display: none !important;
// }
// #mrcrm-guest-dialog{
// 	iframe{
// 		// position: fixed !important;
// 		display: block !important;
// 	}
// }
.logo {
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: initial;
	font-size: 28px;
	background: transparent;
}
.top-header {
	background: #fff;
	// border-bottom: 1px solid $borderColor;
	// padding: 0 15px;
	// padding-bottom: 2px;
	min-width: 1171px;
	
	position: relative;
	.header-logo {
		padding-top: 5px;
		margin-left: 9px;
		width: 42px;
		height: 32px;
		float: left;
		img{
			width:100%;
			height: 15px;
		}
	}
	.header-title {
		height: 32px;
		font-size: 12px;
		font-weight: 500;
		color: #0F0F0F;
		line-height: 15px;
		padding-top: 5px;
		margin-right: 51px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: ABBvoice-Medium;
	}
	.top-header-inner {
		height: 40px;
		display: flex;
		align-items: center;
		// justify-content: space-between;
		width: 100%;
		
		.header-right {
			display: flex;
			// justify-content: space-between;
			align-items: center;
			position: absolute;
			top: 40px;
			right: 0px;
			padding-right: 32px;
			// width: 320px;
			
		}
	}
	.drop-list {
		li {
			padding: 10px 15px;
		}
		.logout{
			transform: rotate(180deg);
		}
	}
}
.header_nav{
	height: 40px;
	background-color: #FFF;
	display: flex;
	justify-content: left;
	align-items: center;
	.header_nav_list, .header_nav_list_active{
		line-height: 40px;
		color: #1F1F1F;
		font-size: 16px;
		width: 171px;
		text-align: center;
		cursor: pointer;
	}
	.header_nav_list_active::after{
		display: block;
		content: ' ';
		height: 2px;
		background-color: #3366FF;
	}
	.header_nav_list:hover{
        background-color: #EBEBEB;
	}
}
.setting, .setting_active{
	height: 31px;
	background-color: #fff;
	padding:3px 10px 0 10px;
	min-width: 55px;
	text-align: center;
	color: #1F1F1F;
	cursor: pointer;
}

.userinfo_box {
	height: 32px;
	margin: 0px 0px 0px 17px;
	color: #1F1F1F;
	cursor: pointer;
}
.userinfo_boxin, .userinfo_box_active{
	height: 32px;
	padding: 4px 0px 9px 0px;
	color: #1F1F1F;
	// border-bottom: 2px solid #fff;
	>div{
		float: left;
	}
}
.userName{
	display: inline-block;
	max-width: 150px;
	height: 31px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.userinfo_boxin:hover{
	background-color: #EBEBEB;
}
.setting_active::after{
	display: block;
	content: ' ';
	height: 2px;
	background-color: #3366FF;
}
.userinfo_box_active{
	border-bottom: 2px solid #3366FF;
}
.setting:hover{
	background-color: #EBEBEB;
}
.shadow-radius {
	// box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	padding: 8px 16px 8px 16px;
	// background-color: #fff !important;
	min-height: 85vh;
}
.shadow-box {
	// box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 4px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	padding: 8px;
	// margin: 10px 0 0 0;
	background-color: #fff !important;
	min-height: 87vh;
	position: relative;
}

.public-title {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid rgb(217, 217, 217);
	margin-bottom: 10px;
}
.customevent{
	width: 20px;
	height: 20px;
	background: url("../../assets/svg/customevent.svg");
    background-size: 100%;
	margin-left: 2px;
}
.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: inline;
	margin: 0;
	padding: 0;
	color: #1890ff;
}

.link-button:hover,
.link-button:focus {
	text-decoration: none;
}
.tags {
	display: flex;
	margin-bottom: 4px;
	.tags-scroll {
		position: relative;
		width: 100%;
		height: 31px;
	}
}
.tags-list {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	padding: 0;
	transition: all 0.2s ease-in-out 0s;
	width: 100%;
	li {
		list-style: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $borderColor;
		padding: 4px 22px;
		position: relative;
		margin-right: 4px;
		word-break: keep-all;
		cursor: pointer;
		&:before {
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			background: #b1b1b1;
			border-radius: 50%;
			left: 8px;
			top: 12px;
			position: absolute;
		}
		&:first-child {
			em,
			&:before {
				display: none;
			}
		}
		em {
			font-style: normal;
			font-size: 16px;
			margin-left: 4px;
			width: 14px;
			height: 14px;
			position: absolute;
			right: 4px;
			top: 2px;
		}
		.hide {
			display: none;
		}
	}
	.isActive {
		color: #fff;
		background: linear-gradient(to right, #2187e8, #1890ff);
		border-color: #1890ff;
		&:before {
			background: #fff;
		}
	}
}


.breadCrumb {
	padding: 15px;
	padding-bottom: 0;
}

.list {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	li {
		width: calc(100% / 8);
		height: 100px;
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
		i {
			font-size: 28px;
			margin-bottom: 5px;
		}
		&:hover {
			background: #e4e4e4;
			transition: all 0.15s ease-in-out 0s;
			i {
				transform: scale(1.5);
				transition: all 0.15s ease-in-out 0s;
			}
		}
	}
}

#dragcontent_a {
	font-size: 16px;
	font-family: ABBvoiceCNSG-Bold;
	font-weight: bold;
	padding-bottom: 10px;
	color: #000000;
	border-bottom: 2px solid #1F1F1F;
	margin-bottom: 16px;
	line-height:20px;
}



#legend1 {
	position: absolute;
	height: 200px;
	right: 30px;
	top: 10px;
	font-size: 16px;
	font-family: ABBvoiceCNSG-Regular;
	font-weight: 400;
}

// 原app。css里面的css
.dragcontent{
    // border: 1px solid #ccc;
    padding: 16px 31px;
    background: #fff;
}

.right-arrow {
    display :inline-block;
    position: relative;
    width: 10px;
    height: 10px;
    
}

.right-arrow::after {
    display: inline-block;
    content: " ";
    height: 12px;
    width: 12px;
    border-width: 2px 2px 0 0;
    border-color: #1F1F1F;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -7px;
}

.user_box{
    font-size: 16px;
    height: 32px;
    display: flex;
    justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}
.tabletopbox{
    font-size: 16px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* border-bottom: 1px solid #d8d8d8; */
    margin-bottom: 10px;
}
.dialog_lable{
    width: 150px;
    line-height: 30px;
    margin-right: 10px;
    
}

.Radio_height{
    line-height: 30px;
}

.tmp_list{
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid #8f8f8f;
}
.tmp_list:last-of-type{
   border: none;
}
.btn{
    /* position: absolute; */
    width: 150px;
    height: 100%;
    /* left: 200px;
    top: 250px; */
    background: red;
    color: #fff;
}

.equ_search_li{
    display: flex;
    width: 100px;
    justify-content: space-between;
    align-items: center;
	.equ_search_li_select{
		width: 24px;
		height: 24px;
		cursor: pointer;
		font-size: 14px;
		color: #fff;
		margin: 0 0px 0 1px;
		line-height: 16px;
		text-align: center;
		// background-color: #DEF4FA;
		padding: 3px;
		border: 1px solid #fff;
		.equ_search_li_div{
			width: 16px;
			height: 16px;
		}
	}
}
.equ_search_li_select_click{
	width: 24px;
	height: 24px;
	cursor: pointer;
	font-size: 14px;
	color: #fff;
	margin: 0 0px 0 1px;
	line-height: 16px;
	text-align: center;
	background-color: #DEF4FA;
	padding: 3px;
	border: 1px solid #6CD5FF !important;
}
.equ_search_li_select:nth-of-type(1) .equ_search_li_div{
	
	background: #0CA919 url("../img/Condition1.png") no-repeat center;
	background-size: 95%;
}
.equ_search_li_select:nth-of-type(2) .equ_search_li_div{
	background: #FFD800 url("../img/yellow_icon.png") no-repeat center;
	background-size: 95%;
}
.equ_search_li_select:nth-of-type(3) .equ_search_li_div{
	background: #F03040 url("../img/Condition3.png") no-repeat center;
	background-size: 95%;
}
.equ_search_li_select:nth-of-type(4) .equ_search_li_div{
	background: #BABABA url("../img/Condition4.png") no-repeat center;
	background-size: 95%;
}
.asset_type_icon{
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
	background: #fff;
	background-size: 95%;
    margin: 0px 4px 0 10px;
    color: #fff;
}
.asset_type_icon1{
	background: url("../img/Condition1.png") no-repeat center;
	background-size: 95%;
}
.asset_type_icon2{
	background: url("../img/Condition3.png") no-repeat center;
	background-size: 95%;
}
.asset_type_icon3{
	background: url("../img/Condition4.png") no-repeat center;
	background-size: 95%;
}
.asset_type_icon4{
	background: url("../img/yellow_icon.png") no-repeat center;
	background-size: 95%;
}
.asset_icon{
    margin: 0 8px;
}


.treeNode_box>div{
    display: none;
}
/* .treeNode_box:hover div{
   display: block;
} */

.amapserch{
    position: absolute;
    top:0;
    left: 0;
    background: #fff;
}

// ------end----

.MyNav {
	font-size: 16px;
	font-family: ABBvoiceCNSG-Regular;
	font-weight: 400;
	color: #1F1F1F;
	// background: #ebebeb;
	line-height: 40px !important;
}

.dashboardContent{
	padding: 0 8px 8px 8px;
}

@font-face {
    font-family: 'ABBvoiceCNSG-Regular';
	src: url('../fonts/ABBvoice_CNSG_Rg.ttf') format("truetype");
}
@font-face {
	font-family: 'ABBvoiceCNSG-Medium';
	src: url('../fonts/ABBvoice_CNSG_Md.ttf') format("truetype");
}
@font-face {
	font-family: 'ABBvoiceCNSG-Bold';
	src: url('../fonts/ABBvoice_CNSG.ttf') format("truetype");
}
@font-face {
	font-family: 'ABBvoice-Medium';
	src: url('../fonts/ABBvoice_Md.ttf') format("truetype");
}


.box_flex{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	color: #1f1f1f;
}


.remove_model{
	font-size: 16px;
	color: #1F1F1F;
	line-height: 32px;
}

.example {
	width: 100%;
	height: 400px;
	text-align: center;
	
	margin-top: 180px;
}

.gutter-row {
	margin-top: 20px;
	// line-height: 30px;
}






.cancelButton{
	width: 101px;
	height: 32px;
	color: #1F1F1F;
}
.cancelButton:hover{
	border: 1px solid #9F9F9F;
}
.cancelButton:focus{
	background: #DBDBDB;
	border: 1px solid #686868;
}


.loginout{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 200px;
	margin: auto;
}


.modelButton{
	width: 101px;
	height: 32px !important;
}
.modelButton:hover{
	background: #2A35FF;
}
.modelButton:focus{
    background: #2C20D4;
}

.model_title{
	line-height: 32px;
	font-size: 14px;
	color: #686868;
}
//输入框相关
.model_input{
	border: 1px solid #BABABA !important;
	.ant-input{
		border-bottom: none !important;
	}
}
.model_input:hover{
	background: #EBEBEB;
	border: 1px solid #9F9F9F !important;
}
.model_input:focus-within{
	border-bottom: 2px solid #3366FF !important;
	border-color: #BABABA;
    box-shadow: none;
}
.model_list{
	margin-bottom: 20px;
}
.model_list_sub{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.model_list:last-child {
	margin-bottom: 0;
}


.asset_list_active{
	height: 32px;
	background: #DBDBDB;
	padding-top: -2px;
}
.asset_list_active::before{
	display: inline-block;
	content: '';
	width: 4px;
	height: 31px;
	
	position: absolute;
	left: 0;
	background: #36f;
}


.newcount{
	display: inline-block;
}

.abb_client_class{.ant-table-row-level-0{
    background-color: #f0f0f0;
}}

.eventTitle{
	line-height: 18px;
	font-size: 14px;
	color: #686868;
	margin-bottom: 10px;
}

.motor_checkout{
	justify-content: left;
	align-items: inherit;
	margin-bottom: 16px;
	div{
		margin-right: 8px;
	}
}

.assetBtnShow{
	width: 48px;
	height: 48px;
	padding: 2px 0 0 15px;
}
.assetBtnShow:hover{
	background: #CCCCCC;
}
.assetBtnShow:active{
	background: #dbdbdb;
}
// 公司信息
.org_information{
	font-size: 14px;
	color: #686868;
	height: 18px;
	margin-bottom: 5px;
	margin-top:  8px;
 
}
.org_information_list{
	color: #1F1F1F;
	height: 18px;
	margin-bottom: 16px;
	justify-content: left;
	align-items: center;
	
	.org_information_list_lable{
		display: inline-block;
		min-width: 60px;
		text-align: justify;
		text-align-last: justify;
	}
}

// 加入组织
.join_box{
	width: 100%;
	height: 80vh;
	position: relative;
	display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */  
	display: -moz-box; /* Firefox 17- */  
	display: -webkit-flex; /* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */  
	display: -moz-flex; /* Firefox 18+ */  
	display: -ms-flexbox; /* IE 10 */  
	display: flex; /* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */  
	.join_center{
		width: 216px;
		height: 80px;
		
		margin: auto;
		.join_botton{
		    margin-left: 55px;
		}
		.joinorg{
			width: 216px;
			font-size: 12px;
			color: #686868;
			text-align: center;
			margin-top: 16px;
		}
	}
	
}
.renderEmpty{
	height: 100%;
	font-size: 12px;
	font-family: ABBvoiceCNSG-Regular;
	line-height: 15px;
	color: #1F1F1F;
}
.renderEmpty_flex{
	display: flex;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}
.renderEmpty_center{
	width: 50px;
	margin: auto;
}
.motor_box{
	height: 200px;
	overflow-y: scroll;
}


.device_list_position{
	width: 259px;
	position: absolute;
	top: 8px;
	left: 0px;
}
.device_list_box{
	height: 72vh;
	position: relative;
	top: 16px;
}
.device_scroll{
	width: 259px;
	height: 83%;
	overflow-y: scroll;
	position: absolute;
	top: 60px;
}

.closeButton{
	width: 32px !important;
	height: 32px !important;
	padding-top: 4px;
}
.closeButton:hover{
	background-color: rgba(132,132,132,0.3);
	border-radius: 50%;
}
.closeButton:active {
	background-color: rgba(132,132,132,0.6);
	border-radius: 50%;
}

.generalnformation{
	display: flex;
	width: 100%;
	background: #fff;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 16px;
	position: relative;
	min-height: 240px;
}
.signal_char{
	width: 49.5%;
	border: 1px solid #BABABA;
	margin-bottom: 10px;
}
.signal_char_box{
	padding: 10px 0 0 0;
    height: 246px;
	position: relative;
}
.signal_char_deviceNone{
	width: 33%;
	border: 1px solid #BABABA;
	margin-bottom: 10px;
}
// @media screen and (min-width:1366px) {
//     .signal_char{
// 		width: 33%;
// 		border: 1px solid #BABABA;
// 		margin-bottom: 10px;
// 	}
// }
.nodatagroup{
	margin-bottom: 20px;
}
.ownIdlist{
	line-height: 20px;
}
.ownIdlist::before{
	display: inline-block;
	content: '√';
	background-color: #ececee;
	border: 1px solid #ccc;
	text-align: center;
	height: 16px;
	width: 16px;
	margin-right: 8px;
	border-radius: 2px;
	line-height: 16px;
}

.plant_list{
	line-height: 32px !important;
}

.nopermission_center{
	width: 150px;
	margin: auto;
	position: relative;
}
.nopermission_icon{
	width: 50px;
	height: 65px;
	background: url("../../assets//img/noperission.png") no-repeat center;
	background-size: 100% 100%;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
}
.nopermission_title{
	font-size: 20px;
	margin-top: 80px;
}
.maphover{
	width: 100%;
	height: 295px;
	position: relative;
	.maptitle{
		display: none;
		position: absolute;
		top: -16px;
		left: 0px;
		z-index: 99999;
		background: rgba(0, 0,0,0.4);
		width: 100%;
		padding: 4px 8px;
		color: #fff;
		font-size: 10px;
	}
}
.maphover:hover{
	
	.maptitle{
		display: block;
		position: absolute;
		top: -16px;
		left: 0px;
		z-index: 99999;
		background: rgba(0, 0,0,0.4);
		width: 100%;
		padding: 4px 8px;
		color: #fff;
		font-size: 10px;
	}
}