.list-news {
	margin: 0px 0;
	cursor: pointer;

	.ant-list-item {
		display: flex;
		justify-content: left;
		padding-right: 4px;
		padding-top: 6px;
		padding-bottom: 5px;

		// border-left: 4px solid #fff;
		.list-title {
			flex: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 16px;
		}

		.list-time {
			color: #3d3d3d;
			font-size: 14px;
			width: 62%;
			overflow: hidden;
			// text-overflow:ellipsis;
			white-space: nowrap;
			display: flex;
			align-items: center;

			.devicePovertrain {
				color: #8d8d8d;
				font-size: 14px;
				line-height: 30px;
			}
		}
	}
}

.event_list_box {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 8px;

	.event_list_select {
		margin-right: 16px;
		margin-bottom: 8px;
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: #36f;
		border-color: #36f;
	}
}

.Checkbox_event {
	display: flex;
	width: 100%;

	.Checkbox_event_list {
		margin-right: 24px;

		>span {
			margin-left: 8px;
			color: #1f1f1f;
			font-size: 14px;
		}

	}
}

.ant-list-item:hover {
	background-color: #dbdbdb;
}

.newcount {
	display: inline-block;
}

.deviceTime-box {
	display: flex;
	align-items: center;
}

.exceptionBox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// background-color: #36f;
}

.backup_top_box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	margin-top: 16px;
}

.EUorg_title_back {
	line-height: 18px;
	font-size: 16px;
	display: flex;
	justify-content: left;
	align-items: center;
	color: #1f1f1f;
	margin-bottom: 8px;

	>span {
		margin: 0 4px;
	}

	>span:last-of-type {
		color: #36f;
	}
}

.costom_title {
	font-size: 14px;
	color: #1f1f1f;
}

.abilitytext {
	font-size: 14px;
	color: #686868;
	padding-left: 8px;
}

.abilitycostom_title {
	padding: 8px;
	background: #f5f5f5;
	border-bottom: 1px solid #BABABA;
}

.costom_box {

	// background-color: pink;
	.costom_body {
		font-size: 12px;
		font-family: "ABBvoiceCNSG-Medium";
		padding: 8px;
		border-bottom: 1px solid #1f1f1f;
	}

	.alarms-box {
		max-height: 200px;
		overflow: auto;
	}

	.costom_list {
		font-size: 14px;
		color: #1f1f1f;
		// font-family: "ABBvoiceCNSG-Medium";
		padding: 8px;
		border-bottom: 1px solid #ebebeb;
	}
}

.abilitynodata {
	text-align: center;
	margin-top: 20px;
}

.char_button_box {
	position: absolute;
	top: 14px;
	right: 7px;
	z-index: 9999;
	background-color: #fff;
	width: 100px;
	height: 18px;
	display: flex;
	justify-content: space-between;
	padding-left: 40px;
	padding-right: 8px;
}

.restorebutton {
	display: block;
	width: 16px;
	height: 18px;
	background: url("../../assets/svg/abb_write-to-default.svg")no-repeat 0px 1px;
	background-size: 100%;
	// position: absolute;
	// top: 14px;
	// right: 7px;
	// z-index: 9999;
}

.restorebutton:hover {
	height: 40px;

	// background:chocolate;
	.restoretoolip {
		display: block;
	}
}

.restoretoolip {
	display: none;
	width: 40px;
	font-size: 13px;
	color: #36f;
	margin-left: -5px;
	margin-top: 18px;
}

.detailbutton {
	width: 16px;
	height: 18px;
	// position: absolute;
	// top: 14px;
	// right: 85px;
	// z-index: 9999;
}

.detailbutton:hover {
	height: 40px;

	// background:chocolate;
	.detailtoolip {
		display: block;
	}
}

.detailtoolip {
	display: none;
	width: 40px;
	font-size: 13px;
	color: #36f;
	margin-left: -5px;
	margin-top: -5px;
}

.devicemenulist {
	display: flex;
	align-items: center;
}

.devicemenulistIcon {
	width: 16px;
	height: 16px;
	background: url("../../assets/img/backup.png") no-repeat center;
	background-size: 100%;
}

.event_group_header {
	font-size: 12px;
	color: #686868;
	padding: 8px 10px 8px 14px;
	border-bottom: 1px solid #1F1F1F;
	font-weight: 500;
}

.event_group_body {
	font-size: 14px;
	color: #1f1f1f;
	padding: 15px 12px;
	border-bottom: 1px solid #BABABA;
}

.logtitle {
	font-size: 10px;
	color: #1f1f1f;
}

.lodlist {
	width: 220px;
	display: flex;
	font-size: 10px;
	justify-content: space-between;
	white-space: normal;
}

.data-migration {
	cursor: pointer;
	background: #E9F6FE;
	border: 1px solid #A0D4FB;
	border-radius: 4px;
	height: 25px;
	font-size: 12px;
	// line-height: 25px;
	padding: 0 8px;
	margin-left: 20px;
	color: #4390F7;
	display: flex;
	align-items: center;

	.migration-blue {
		height: 12px;
		width: 10px;
		// background: red;
		// line-height: 25px;
		margin-right: 4px;
	}

	.migration-blue-left {
		background: url("../../assets/svg/blue-left.svg") no-repeat center;
		background-size: 100% auto;
	}

	.migration-blue-center {
		background: url("../../assets/svg/blue-center.svg") no-repeat center;
		background-size: 100% auto;
	}

	.migration-blue-right {
		background: url("../../assets/svg/blue-right.svg") no-repeat center;
		background-size: 100% auto;
	}
}

.data-migration-subscriptionType {
	cursor: pointer;
	background: #FEFBE8;
	border: 1px solid #FBE59A;
	border-radius: 4px;
	height: 25px;
	font-size: 12px;
	// line-height: 25px;
	padding: 0 8px;
	margin-left: 20px;
	color: #EFAF41;
	display: flex;
	align-items: center;

	.migration-blue {
		height: 12px;
		width: 10px;
		// background: red;
		// line-height: 25px;
		margin-right: 4px;
	}

	.migration-yellow-left {
		background: url("../../assets/svg/yellow-left.svg") no-repeat center;
		background-size: 100% auto;
	}

	.migration-yellow-center {
		background: url("../../assets/svg/yellow-center.svg") no-repeat center;
		background-size: 100% auto;
	}

	.migration-yellow-right {
		background: url("../../assets/svg/yellow-right.svg") no-repeat center;
		background-size: 100% auto;
	}
}

.data-migration-Expired {
	cursor: pointer;
	background: #FDF2F0;
	border: 1px solid #F7CEC8;
	border-radius: 4px;
	height: 25px;
	font-size: 12px;
	// line-height: 25px;
	padding: 0 8px;
	margin-left: 20px;
	color: #EC5B56;
	display: flex;
	align-items: center;

	.migration-blue {
		height: 12px;
		width: 10px;
		// background: red;
		// line-height: 25px;
		margin-right: 4px;
	}

	.migration-red-left {
		background: url("../../assets/svg/red-left.svg") no-repeat center;
		background-size: 100% auto;
	}

	.migration-red-center {
		background: url("../../assets/svg/red-center.svg") no-repeat center;
		background-size: 100% auto;
	}

	.migration-red-right {
		background: url("../../assets/svg/red-right.svg") no-repeat center;
		background-size: 100% auto;
	}
}




.migration-Tooltip {
	padding: 20px;
	// width: 500px;
}

.migration-title {
	font-size: 16px;
	font-weight: 700;
}

.migration-model {
	.ant-modal-title {
		color: #fff;
	}
}

.migration-title-model {
	margin-top: -30px;
}

.migration-text {
	margin-top: 20px;
	font-size: 16px;
	// width: 500px;
	color: #1f1f1f;
}

.subscription-box {
	// background: red;
	// height: 30px;
	position: absolute;
	bottom: 15px;
}

.online {
	width: 24px;
	height: 16px;
	background: url("../../assets/img/onLine.png") no-repeat center;
	background-size: 100%;
}

.offline {
	width: 22px;
	height: 16px;
	background: url("../../assets/img/offLine.png") no-repeat;
	background-size: 100%;
}

.backup_button {
	height: 22px;
	font-size: 16px;
	color: #1F1F1F;
	min-width: 100px;
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1000;
	// display: inline;
}
