.Piecharts_box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .charts_list{
        .charts_num{
            width: 100%;
            text-align: center;
            color: #545454;
            font-size: 20px;
        }
        .charts_content{
            font-size: 14px;
            color: #545454;
            margin: 0 4px;
            text-align: center;
            .charts_icon{
                width: 14px;
                height: 14px;
                border-radius: 50px;
                margin-right: 4px;
                // background-color: #36f;
                margin: auto;
                margin-bottom: 4px;
            }
        }
    }
}