.line {
	height: 1px;
	background-color: #bababa;
	width: 100%;
	margin-bottom: 16px;
}

.org_information_button {
	display: flex;

	span {
		color: #FF000F;
		margin-right: 32px;
		cursor: pointer;
	}
}

.tranfer-title {
	color: #1f1f1f;
	margin-bottom: 10px;
}

.tranfer-phone {
	font-size: 18px;
	color: #1f1f1f;
	font-family: "ABBvoiceCNSG-Bold";
}

.tranfer-text {
	color: #464646;
	font-size: 14px;
	display: flex;
	align-items: center;
	margin-top: 11px;
}


.contact_content {
	width: 450px;

	.instructions {
		height: 36px;
		color: #1f1f1f;
		font-size: 14px;
	}

	.input_content {
		margin-top: 16px;

		.item_title {
			color: #686868;
		}

		.input_box {
			display: flex;


			.input_left {
				width: 152px;
			}

			.input_right {
				margin-left: 10px;
				width: 400px;
			}
		}
	}


}

.information_title_wrapper {

	display: flex;
	justify-content: space-between;
}

.contact_section_item {
	display: flex;
	color: #686868;
	font-size: 14;
	font-weight: 500;
	align-items: center;
	margin-bottom: 4px;

	.section_icon {
		background: url("../../../../assets/img/icon_star.png") no-repeat center;
		width: 10px;
		height: 10px;
	}
}

.title_name_box {

	display: flex;
	margin: auto;

}

.from_list_sub {}

.orgToolTip {
	padding: 2px 0 0 5px;
}

.orgToolTip:hover {}

.orgToolTip:active {}

.industry_box{
	max-height: 600px;
	overflow-y: auto;
	.industry_header {
		border-bottom: 1px solid #1F1F1F;
		height: 30px;
		display: flex;
	
		.left {
			width: 150px;
			padding-left: 16px;
		}
		.right{
			padding-left: 16px;
		}
	}
	.item_box{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #BABABA;
		.left{
			width: 150px;
			padding-left: 16px;
			
			// background-color: pink; 
		}
		.right {
			border-left: 1px solid #BABABA;
			flex: 1;
			// background: red;
			padding: 8px 16px;
			display: flex;
			flex-wrap: wrap;
			>div{
				width: 20%;
				margin: 5px 0;
			}
		}
	}
}
.userOrg  {
	font-size: 12px;
    color: #1f1f1f;
    // margin-bottom: 12px;
     
}
.userOrgTitle {
	// font-size: 14px;
    // color: #1f1f1f;
    // margin-bottom: 12px;
    font-family: "ABBvoiceCNSG-Bold";
}
.userTitle {
 
      color: #3366FF;
 
   
}