.api_title {
    margin-top: 8px;
    color: #686868;
    font-size: 14px;
}
.api_list {
    font-family: ABBvoiceCNSG-Medium, ABBvoiceCNSG;
    display: flex;
    align-items: center;
    padding: 16px 0 0 0;
    font-size: 14px;
    color: #1f1f1f;
    font-weight: 500;
    .api_label {
        width: 160px;
    }
    .api_Secret_key {
        color: #36f;
        padding: 8px;
        margin-left: 60px;
        cursor: pointer;
    }
}
.sk_tip {
    color: #f03040;
    font-size: 13px;
    font-family: ABBvoiceCNSG-Regular, ABBvoiceCNSG;
}
.sk_box {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: ABBvoiceCNSG-Regular, ABBvoiceCNSG;
    font-weight: 400;
    color: #1f1f1f;
    margin-bottom: 16px;
    .copysk {
        margin-left: 40px;
        color: #36f;
        font-size: 14px;
        cursor: pointer;
    }
}
.Afresh_sk_tip {
    font-size: 16px;
    font-family: ABBvoiceCNSG-Regular, ABBvoiceCNSG;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 24px;
    margin-bottom: 16px;
}
