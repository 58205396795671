.EUorg_title {
    color: #1f1f1f;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;

    padding: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
    font-family: ABBvoiceCNSG-Regular;
    background: #ebebeb;
}
.asset_title {
    color: #1f1f1f;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;

    margin-top: 16px;
    padding-bottom: 8px;
    font-family: ABBvoiceCNSG-Regular;
}
.orgtree_box {
    position: fixed;
}
.orgtree_top {
    position: absolute;
}
.orgtree_bottom {
    width: 279px;
    position: absolute;
    top: 140px;
    left: 0;
    height: 83%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.org_name {
    font-size: 14px;
    color: #1f1f1f;
    padding: 8px 0 9px 9px;
}
.asset_name {
    font-size: 14px;
    color: #1f1f1f;
    padding: 0px 7px 17px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.devicedetail_title {
    display: flex;
    width: 100%;
    // justify-content: space-between;
}
.asset_type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #1f1f1f;
    margin-bottom: 2px;
    margin-right: 16px;
}
.asset_list_title {
    // line-height: 32px;
    padding: 0 9px;
    font-size: 14px;
    color: #1f1f1f;
    font-family: ABBvoiceCNSG-Medium;
}
.EUorg_title::before {
    // display: inline-block;
    // content: '';
    // width: 17px;
    // height: 4px;
    // background-color: #F03040;
    // position: absolute;
    // top: 0;
    // left: 0;
}
.device_title {
    width: 100%;
    font-size: 14px;
    color: #686868;
    position: relative;
    // padding: 0 0 0 10px;
}
.icon_down {
    font-size: 8px;
    margin-left: 10px;
}
// .device_title::before{
//     display: inline-block;
//     content: '';
//     width: 4px;
//     height: 21px;
//     background-color: #F03040;
//     position: absolute;
//     top: 3px;
//     left: 0;

// }
.device_detail_genera_title {
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;
    background: #ebebeb;
    padding: 8px;
}
.ant-tree {
    font-size: 14px;
    color: #3d3d3d;
}

.deviceDetail_status_bottom {
    margin-right: 16px;
    padding: 10px;
    min-width: 700px;
    background: rgba(255, 255, 255, 1);
    position: relative;
    z-index: 999;
}


.tryoutsub {
    line-height: 46px;
    background: #ffd800;
    font-size: 24px;
    color: #1f1f1f;
    padding-left: 9px;
    display: flex;
    align-items: center;
}
.hide {
    display: none;
}
.deviceDetail_status {
    margin: 0 16px 8px 0;
    padding: 10px;
    // height:200px;
    background: rgba(255, 255, 255, 1);
    // border:1px solid rgba(219,219,219,1);
    // border-radius:4px;
    min-width: 700px;
    overflow: auto;
}

.assetbox {
    // padding: 10px;
    // height:200px;
    // background:rgba(255,255,255,1);
    // border:1px solid rgba(219,219,219,1);
    border-radius: 4px;
    margin: 0 16px 16px 8px;
}
.gen_li {
    width: 170px;
    // background: pink;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    line-height: 30px;
    font-size: 16px;
    color: #3d3d3d;
    position: relative;
}
.devie_gen_icon {
    // width: 28px;
    height: 50px;
    padding: 10px 9px 0 0;
}
.device_gen_title > span {
    font-size: 16px;
    color: #1f1f1f;
    display: block;
}
.cmd_api_icon{
    width: 100px;
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 10px 0px 0 0;
}
.cmd_api{
    width: 100px;
    text-align: center;
    font-size: 16px;
    color: #1f1f1f;
    display: block;
}
.device_gen_title > div {
    color: #1f1f1f;
    font-size: 12px;
    line-height: 15px;
    margin-top: -6px;
}

.atissue {
    color: #4c85ff;
    font-size: 16px;
    margin-left: 10px;
}
.ant-list-split .ant-list-header {
    border-bottom: none;
}
.ant-list-split .ant-list-item {
    border-bottom: none;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background: #fff;
    color: #3366ff;
}

.device_detail_title_center {
    font-size: 14px;
    color: #1f1f1f;
    span {
        margin: 0 10px;
    }
}

.org_right::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: #fff;
}
/*定义滚动条轨道 内阴影*/
.org_right::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #fff;
}

/*定义滑块 内阴影*/
.org_right::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #d5d5d5;
}

.org_tree_scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: #ebebeb;
}
/*定义滚动条轨道 内阴影*/
.org_tree_scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #ebebeb;
}

/*定义滑块 内阴影*/
.org_tree_scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #fff;
}

.orgtreeRadio{
    padding: 8px 0 8px 8px;
    // background: #fff;
    margin: 0 0 0 8px;
    .ant-radio-group{
        // width: 84%;
        display: flex;
        justify-content: left;
        align-items: center;
        .ant-radio-wrapper{
            width: 85px;
        }
    }
}

.asset_right::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: none;
}
.menu_submenulist {
    // width: 200px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ant-menu-submenu-title_center {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 200px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
