.loginBox {

    width: 100%;
    height: 100vh;
    display: flex;

    .leftimg {
        width: 100%;
        // flex-shrink: 0;
        // height: 100%;
    }

    // @media screen and (max-width: 1920px) {
    //     .leftimg {
    //         background: url('https://download.digital.motion.abb.com.cn/public/portal-img/left-login-big.png');
    //         background-position: center;
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //     }
    // }

    // @media screen and (max-width: 1470px) {
    //     .leftimg {
    //         background: url('https://download.digital.motion.abb.com.cn/public/portal-img/left-login.png');
    //         background-position: center;
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //     }
    // }

    .right-cont {
        width: 530px;
        flex-shrink: 0;
        height: 100%;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        padding-top: 60px;

        .img-box {
            width: 100%;
            height: 32px;
            display: flex;
            justify-content: end;

            .logo-img {

                width: 88px;
                height: 32px;
            }
        }

        .login-title {
            margin-top: 48px;
            display: flex;
            flex-direction: column;
            height: 56px;
            justify-content: space-between;
            padding-bottom: 10px;
            box-sizing: border-box;
            .login-title-line {

                width: 39px;
                height: 4px;
                background: #FF000F;
            }

            .login-title-text {
                font-weight: 700;
                font-size: 25px;
                color: #1F1F1F;
                font-family: "ABBvoice CNSG";
                line-height: 31.75px;
            }
        }

        .login-tips {

            margin-top: 32px;
            font-weight: 400;
            font-size: 20px;
            color: #1F1F1F;
            font-family: "ABBvoice CNSG";
            line-height: 24px;
        }

        .login-ul-box {

            margin-top: 16px;
            font-family: "ABBvoice CNSG";
            margin-left: -6px;

            .login-ul-li {
                list-style: disc;
                font-weight: 500;
                font-size: 20px;
                color: #1F1F1F;

            }
        }

        .login-btns {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            align-items: center;
            font-family: "ABBvoice CNSG";

            .login-left-btn {
                width: 128px;
                height: 48px;
                font-weight: bold;
                font-size: 16px;
                border-radius: 24px;
                background: #3366FF;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                cursor: pointer;

                .rightIcon {

                    margin-right: 8px;
                    width: 20px;
                    height: 16px;
                }
            }

            .login-right-btn {
                color: #1F1F1F66;
                font-weight: bold;
                font-size: 16px;
                cursor: pointer;
            }

            .login-right-btn::after {
                content: "";
                display: block;
                border-bottom: 1px solid #1F1F1F66;
            }
        }
    }
}