.eventdetailbox{
    background: #fff;
    padding: 8px 16px;
    margin: 0 16px 0px 0;
    font-size: 16px;
    color: #1f1f1f;
    .eventdetaillist{
        display: flex;
        // align-items: center;
        margin: 0 0 16px 0;
        >div:first-of-type{
            width: 100px;
            // background-color: red;
        }
        >div:last-of-type{
            flex:1
        }
    }
}
.life_back{
    font-size: 16px;
}
.eventtype{
    display: flex;
    align-items: center;
}

.icon_center{
    margin: 0 10px 0 0;
	display:  flex ;
	color: #3366ff;
    align-items: center;
}
.device_status{
    display: flex;
    align-items: center;
     
}