 
.section_item {
	display: flex;
	color: #686868;
	font-size: 14;
	font-weight: 500;
	align-items: center;
	margin-bottom: 4px;

	.section_icon {
		background: url("../../../../../assets/img/icon_star.png") no-repeat center;
		width: 10px;
		height: 10px;
	}
}

.title_name_box {

	display: flex;
	margin: auto;

}

.from_list_sub {}

.orgToolTip {
	padding: 2px 0 0 5px;
}

.orgToolTip:hover {}

.orgToolTip:active {}

.industry_box{
	max-height: 600px;
	overflow-y: auto;
	.industry_header {
		border-bottom: 1px solid #1F1F1F;
		height: 30px;
		display: flex;
	
		.left {
			width: 150px;
			padding-left: 16px;
		}
		.right{
			padding-left: 16px;
		}
	}
	.item_box{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #BABABA;
		.left{
			width: 150px;
			padding-left: 16px;
			
			// background-color: pink; 
		}
		.right {
			border-left: 1px solid #BABABA;
			flex: 1;
			// background: red;
			padding: 8px 16px;
			display: flex;
			flex-wrap: wrap;
			>div{
				width: 20%;
				margin: 5px 0;
			}
		}
	}
}

