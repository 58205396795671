.select_box{
	display: flex;
	padding: 0 23px;
    flex-wrap: wrap;
    .mydevice_name{
        padding: 4px 5px 0 0;
        color: #1f1f1f;
        white-space: nowrap;
    }
    .tag_box{
        display: flex;
        flex-wrap: wrap;
        .tag_list{
            border-bottom: 2px solid #ff0000;
            // width: 90px;
            padding: 0 16px;
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #1f1f1f;
            background: #E0F0FF;
            margin: 0 12px 16px 0;
            .tag_name{
                margin-right: 8px;
            }
            .tag_close{
                padding-top: 4px;
            }
        }
    }
}
.selectparam{
    width: 100%;
    height: 50vh;
    text-align: center;
    font-size: 14px;
    line-height: 50vh;
    color: #1f1f1f;
}