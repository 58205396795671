.chartsdetail{
    width: 100%;
    height: 500px;
    position: relative;
    .restorebutton{
        top: 4px;
        right: 26px;
    }
    .restorebuttonDetail{
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #1f1f1f;
        display: block;
        background: #fff;
        border-radius: 2px;
        border: 1px solid #1f1f1f;
        position: absolute;
        top: 0px;
        right: 26px;
        z-index: 22;
        cursor: pointer;
    }
    .restorebuttonDetail_Unenable{
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #858585;
        display: block;
        background: #ebebeb;
        border-radius: 2px;
        border: 1px solid #bababa;
        position: absolute;
        top: 0px;
        right: 26px;
        z-index: 9999;
        cursor: pointer;
    }
}
.chartstime{
    width: 100%;
    height: 30px;
    text-align: center;
    margin: 32px 0 8px 0;
    color: #1f1f1f;
}

.nodata{
    width: 100%;
    height: 500px;
    background: #fff;
    position: absolute;
    top: 0;
    line-height: 500px;
    color: #1f1f1f;
    text-align: center;
    z-index: 9998;
}
.chartsLodding{
    width: 100%;
    height: 500px;
    background: #fff;
    position: absolute;
    top: 0;
    line-height: 500px;
    color: #1f1f1f;
    text-align: center;
    z-index: 9999;
}