.notification_setting {
    width: 128px;
    height: 32px;
    font-size: 14px;
    font-family: ABBvoiceCNSG-Medium, ABBvoiceCNSG;
    font-weight: 500;
    color: #1f1f1f;
    line-height: 18px;
    background: #dbdbdb;
    text-align: center;
    line-height: 32px;
}
.push_setting {
    width: 100%;
    line-height: 36px;
    padding-left: 12px;
    height: 36px;
    background: #e0f0ff;
    font-size: 14px;
    font-family: ABBvoiceCNSG-Regular, ABBvoiceCNSG;
    font-weight: 400;
    color: #1f1f1f;
}
.push_list {
    width: 100%;
    background: #fff;
    padding: 6px 12px;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #bababa;
    // align-items: center;
    .push_title {
        width: 17%;
        // height: 18px;
        font-size: 14px;
        font-family: ABBvoiceCNSG-Regular, ABBvoiceCNSG;
        font-weight: 400;
        color: #1f1f1f;
        line-height: 26px;
        // padding-right: 99px;
    }
    .push_switch {
        font-size: 14px;
        font-family: ABBvoiceCNSG-Regular, ABBvoiceCNSG;
        font-weight: 400;
        color: #1f1f1f;
        // line-height: 18px;
        display: flex;
        align-items: flex-start;
        > span {
            display: block;
            width: 60px;
            line-height: 26px;
            // margin-right: 48px;
        }
        .ant-radio-group {
            display: flex;
            align-items: flex-start;
        }
    }
    .Nodisturbing {
        flex: 1;
        margin-left: 30px;
        .Nodisturbingcheckbox {
            margin-top: 6px;
        }
        .ant-time-picker-input{
            border-radius: 0px;
        }
    }
}
.push_radio {
    width: 100%;
    display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    .ant-radio-wrapper {
        padding-top: 5px;
    }
}
.shield_setting {
    background: #fff;
    padding-bottom: 8px;
    height: 55vh;
    overflow: scroll;
    .ant-collapse-borderless {
        background: #fff;
        .ant-collapse-item{
            border: none;
        }
        .ant-collapse-header {
            // height: 36px;
            background: #ebebeb;
            font-size: 14px;
            font-family: ABBvoiceCNSG-Regular, ABBvoiceCNSG;
            font-weight: 400;
            color: #1f1f1f;
            // line-height: 36px;
            margin: 0 8px;
            padding: 8px 9px;
            // border-bottom: 1px solid #bababa;
        }
    }
}
.setting_abb .ABB_CommonUX_TabControl__tabItemRoot{
    padding: 0;
}
.malfunction_switch {
    display: flex;
    align-items: center;
}
.adddevicebutton{
    margin: 20px 0 0 10px;
}
.timepickerbutton{
    cursor: pointer;
    background: #36f;
    padding: 2px 4px;
    width: 50px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
}
.timeline{
    display: block;
    width: 15px !important;
}