/******** 滚动条样式 ********/
* {
  outline: none;

  &::-webkit-scrollbar {
    width: 6px;
		height: 6px;
		background: none;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #bababa;
  }
}


