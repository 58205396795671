.DataInspectionWrapper {
    margin: 0 16px 8px 0;
    min-width: 700px;
    overflow: auto;
    color: #1f1f1f;
    position: relative;
    background-color: #fff;
    padding-top: 0px;


    .header {
        min-width: 700px;
        color: #1f1f1f;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        width: '100%';
        padding-right: 12px;
        align-items: center;

        .lifestatus_title {
            font-size: 16px;
            margin: 12px 12px 5px 12px;
            font-family: ABBvoiceCNSG-Regular;

            span {
                font-size: 12px;
                color: #686868;
            }
        }
    }

    .contentCenter {
        padding-left: 12px;
        display: flex;
        align-items: center;
        color: #1f1f1f;
        font-size: 12px;
        cursor: text;

        @keyframes turnZ {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }


        .loading_icon {
            display: flex;
            margin-right: 8px;
            // background-color: blanchedalmond;
            animation: turnZ 1.2s linear infinite;

            width: 18px;
            height: 18px;

            background: url("../../../../../../../assets/svg/path_circle.svg") no-repeat center;
            background-size: 100%;
        }
    }

    .listContent {
        min-width: 700px;
        color: #1f1f1f;
        background-color: #fff;
        padding-right: 12px;
        padding-bottom: 15px;
        margin-left: 12px;

        .item {
            border-bottom: 1px solid #BABABA;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            color: #1f1f1f;

            .left {
                width: 49%;
                padding-left: 16px;
                display: flex;

                .titleclass {
                    width: 130px;
                }
            }

            .right {
                width: 49%;

                display: flex;

                .titleclass {
                    width: 130px;
                }
            }

        }
    }
}



.logotitle {
    // background-color: aqua;
}