.devicemenulist {
	display: flex;
	align-items: center;
}

.devicemenulistIcon {
	width: 16px;
	height: 16px;
	// background: url("../../assets/img/backup.png") no-repeat center;
	background-size: 100%;
}

.deviceformidbox {
	// height: 20px;
	position: absolute;
	top: 170px;
	left: 390px;
	display: flex;
	align-items: center;

	.deviceformcheckbox {
		font-size: 12px;
		color: #1f1f1f;
		margin-top: 10px;
	}
}

.isRenewSubscription {
	border: 1px solid #0AAA19;
	color: #0AAA19;
	width: 55px;
	text-align: center;
	// padding: 0 4px;
	line-height: 20px;
	border-radius: 2px;
}

.deviceformbox {
	position: relative;

	.deviceformcheckboxIcon {
		color: #1f1f1f;
		// margin: 13px 13px 0 0;
		position: absolute;
		top: -18px;
		right: 1px;
	}

	.inputmessage {
		height: 20px;
		line-height: 20px;
		font-size: 12px;
		color: #686868;
		background-color: #fff;
		// border: 1px solid #1f1f1f;
		position: absolute;
		top: 37px;
	}

	.inputmessagehide {
		display: none;
	}
}

.address_button {
	position: absolute;
	top: -6px;
	right: 0px;

	:hover {
		background-color: rgba($color: #fff, $alpha: 0) !important;
	}
}

.sub_title {
	font-size: 14px;
	color: #1f1f1f;
	margin-bottom: 12px;
	font-family: "ABBvoiceCNSG-Bold";
}

.sub_box {
	padding: 0 8px 12px 8px;
	margin-bottom: 12px;
	border-bottom: 1px solid #BABABA;

	.sub_text {
		font-size: 12px;
	}

	.sub_list {
		color: #1f1f1f;
		font-size: 12px;
		display: flex;
		margin-top: 8px;

		>div:first-of-type {
			width: 130px;
		}
	}

}

.subconfig_box {
	padding: 0 8px 12px 8px;
	margin-bottom: 12px;
	// border-bottom: 1px solid #BABABA;

	.sub_text {
		font-size: 12px;
	}

	.sub_list {
		color: #1f1f1f;
		font-size: 12px;
		display: flex;
		margin-top: 8px;

		.using {
			text-align: center;
			color: #F03040;
			border: 1px solid #F03040;
			border-radius: 2px;
			padding: 0 5px;
			margin-right: 8px;
		}

		.binding {
			text-align: center;
			color: #3366ff;
			border: 1px solid #3366ff;
			border-radius: 2px;
			padding: 0 5px;
			margin-right: 8px;
		}

		.subscribeLevel {
			width: 150px;
		}
	}

	.sub_bottom_content {
		display: flex;
		margin-top: 12px;

		.sub_bottom_icon {
			margin-top: 3px;
		}

		.sub_bottom_title {
			margin-left: 5px;
			font-size: 12px;
		}

	}

}

.deviceBorder {
	width: 100%;
	height: 160px;
	border: #BABABA 1px solid;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
}

.deviceUpLoad {
	color: #1f1f1f;
	font-size: 12px;

	.deviceUpLoad_icon {
		width: 100%;
		display: flex;
		margin-bottom: 7px;

		.uploadData {
			margin: auto;
		}
	}
}

.downLoadTemplate {
	cursor: pointer;
	color: #3366FF;
	text-align: center;
	margin-top: 12px;
	font-size: 12px;
	display: block;
}

.CheckAll {
	font-size: 14px;
	color: #1f1f1f;
	padding-left: 5px;
}

.add_device_list {
	margin: 8px 0;

	.add_device_text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
		color: #1f1f1f;
		margin-right: 8px;
	}


}

.section_item {
	display: flex;
	color: #1f1f1f;
	font-size: 14;
	font-weight: 500;
	align-items: center;
	justify-content: space-between;

	.left {
		display: flex;
		align-items: center;
	}

	.section_icon {
		background: url("../../../../assets/img/icon_star.png") no-repeat center;
		width: 10px;
		height: 10px;
	}

	.right_icon {
		color: #1f1f1f;
		// margin: 13px 13px 0 0;
		position: absolute;
		top: 3px;
		right: 15px;
	}

}

.applyRight_box {

	margin-bottom: 12px;
	font-size: 15px;

	.sub_Title {
		color: #1f1f1f;

		.sub_blueTitle {
			color: #3366FF;
		}
	}
}

.addDevice_process_box {
	height: 50px;
	width: 600px;
	position: relative;

	.addDevice_process_line {
		height: 1px;
		border-bottom: 1px dashed #BABABA;
		width: 520px;
		position: absolute;
		top: 8px;
		left: 33px;

	}

	.textContent {
		width: 570px;
		position: absolute;
		z-index: 100;
		display: flex;
		justify-content: space-between;

		.icon_content {
			width: 16px;
			height: 16px;
			background-color: #fff;
			border: 2px solid #BABABA;
			border-radius: 50%;
			margin: auto;
		}

		.icon_normal {
			border: 2px solid #BABABA;
		}

		.icon_current {
			border: 2px solid #0CA919;
		}

		.icon_selected {
			background-color: #0CA919;
			border: 2px solid #0CA919;
		}

		.icon_style {
			margin-top: -2px;
			margin-left: -2px;
			width: 10px;
			height: 10px;
		}

		.title_1f1f1f {
			margin-top: 6.5px;
			font-size: 10px;
			color: #1f1f1f;
		}

		.title_9f9f9f {
			margin-top: 6.5px;
			font-size: 10px;
			color: #9f9f9f;
		}
	}

}

.addDeviceUnSubscription_footer_content {
	width: "100%";
	display: flex;
	justify-content: space-between;

	.sub_bottom_content {
		display: flex;
		margin-left: 16px;

		justify-content: center;
		align-items: center;

		.sub_bottom_title {
			margin-left: 5px;
			font-size: 12px;
		}

	}

}

.editdevice_address_content {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.sub_bottom_content {
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 25px;

		.sub_bottom_title {
			color: #1f1f1f;
			font-size: 14px;
		}
	}
}


.addDeviceUnSubscription_content {
	.item_content {
		display: flex;
		font-size: 12px;
		color: #1f1f1f;

		.item_left {
			width: 190px;

		}

		.item_right {
			width: 400px;
			margin-left: 10px;
		}
	}

	.subscription_title {
		width: 692px;
		height: 34px;
		background: #EBEBEB;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 8px 62px 8px 8px;
		margin-top: 16px;
		font-size: 14px;
	}

	.subscription_code {
		color: #686868;
		font-size: 14px;
		margin-left: 8px;
		margin-top: 8px;
	}

	.subscription_data {
		display: flex;
		color: #1f1f1f;
		width: 420px;
		font-size: 14px;
		margin-left: 8px;
		margin-top: 8px;
		justify-content: space-between;
	}

	.subscription_input_box {
		margin-top: 4px;

		.subscription_input {
			width: 428px;
			border: 1px solid #BABABA;
			height: 32px;
			margin-left: 8px;
		}

		.subscription_check_btn {
			margin-left: 10px;
			margin-top: 4px;
		}
	}

	.subscription_unbindable {
		color: #F03040;
		font-size: 14px;
		margin-top: 8px;
	}

	.subscription_notavailable_bindable {
		color: #1F1F1F;
		font-size: 14px;
		margin-top: 8px;
		font-family: ABBvoiceCNSG-Bold;
	}
}

.map_header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.model_title {
		line-height: 22px;
		font-size: 14px;
		color: #686868;
	}

	.model_button {
		color: #1f1f1f;
		font-size: 12px;
		cursor: pointer;
	}

}

.bindsub-box {
	display: flex;

	>div {
		cursor: pointer;
		line-height: 32px;
		text-align: center;
		color: #3366FF;
		margin-right: 40px;
	}

	>div:hover {
		color: #1038af;
	}

	.bindsub-disabled {
		color: #9f9f9f;
	}
}

.abind_icon {
	cursor: pointer;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: url("../../../../assets/svg/abb_activate_16.svg") no-repeat center;
	background-size: auto;
}
