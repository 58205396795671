.ABB_CommonUX_Icon__root{
    cursor: pointer;
}
.ABB_CommonUX_TabControl__root{
	min-height: 87vh;
	position: relative;
}
.ABB_CommonUX_TabControl__light.ABB_CommonUX_TabControl__primary>ul>li{
	min-width: 128px;
	font-weight: 500;
}
.ABB_CommonUX_TabControl__root>ul{
	overflow-x: auto;
}
.ABB_CommonUX_TabControl__root>ul>li>a{
	text-align: center;
}
.ABB_CommonUX_TabControl__tabItemRoot{
	padding: 0 16px 16px 16px;
	
}
.ABB_tab_marginTop{
	margin-top: 16px;
}
.ABB_CommonUX_Button__root.ABB_CommonUX_Button__light.ABB_CommonUX_Button__ghost{
	border: 1px solid #1f1f1f;
}