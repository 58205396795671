.readstatus {
    font-size: 14px;
    // margin-top: 10px;
    display: flex;
    align-items: center;
    .read1 {
        width: 100px;
        color: #75ba70;
        margin-right: 16px;
        cursor: pointer;
    }
    .read2 {
        width: 100px;
        color: #f03040;
        cursor: pointer;
    }
}
.notification_box::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: none;
}
.notification_table_box .ant-table-tbody .ant-checkbox-disabled .ant-checkbox-inner{
    display: none !important;
}