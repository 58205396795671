.deviceDetail_status_top {
    margin-right: 16px;
    min-width: 700px;
    background: rgba(255, 255, 255, 1);
    position: relative;

    .device_setting_icon {
        position: absolute;
        right: 16px;
        top: 12px;
        display: flex;
    }

    .deviceHeader {
        display: flex;
        align-items: center;

        .subscribe {
            border: 1px solid #3366FF;
            color: #3366FF;
            margin-left: 16px;
            font-size: 10px;
            line-height: 16px;
            width: 56px;
            text-align: center;
        }
    }

    .assetName {
        line-height: 46px;
        background: #e0f0ff;
        font-size: 24px;
        color: #1f1f1f;
        padding-left: 9px;
        display: flex;
        align-items: center;
    }

}

.deviceDetail-title-box {
    margin-right: 16px;
    min-width: 700px;
    background: #e0f0ff;
    position: relative;

    .device_setting_icon {
        position: absolute;
        right: 16px;
        top: 12px;
        display: flex;
    }

    .deviceHeader {
        display: flex;

        align-items: center;

        .subscribe {
            border: 1px solid #3366FF;
            color: #3366FF;
            margin-left: 16px;
            font-size: 10px;
            line-height: 16px;
            width: 56px;
            text-align: center;
        }

    }
 
    .assetName {
        line-height: 46px;

        font-size: 24px;
        color: #1f1f1f;
        padding-left: 9px;
        display: flex;
        align-items: center;
    }

}

.deviceDetail-title-box_red {
    margin-right: 16px;
    min-width: 700px;
    background: #e82222;
    position: relative;

    .device_setting_icon {
        position: absolute;
        right: 16px;
        top: 12px;
        display: flex;
    }

    .deviceHeader {
        display: flex;

        align-items: center;

        .subscribe {
            border: 1px solid #3366FF;
            color: #3366FF;
            margin-left: 16px;
            font-size: 10px;
            line-height: 16px;
            width: 56px;
            text-align: center;
        }
    }

    .assetName {
        line-height: 46px;

        font-size: 24px;
        color: #1f1f1f;
        padding-left: 9px;
        display: flex;
        align-items: center;
    }
}

.device-name-bottom-text {
    margin: 7px 0 0px 0px;
    padding-bottom: 10px;
    font-size: 12px;
    width: 750px;

    .serviceButton {
        color: #3366FF;
        cursor: pointer;
    }
}
.serviceButton {
    color: #3366FF;
    cursor: pointer;
}
.title_bottom {
    margin-right: 16px;
    min-width: 800px;
    background-color: #FFF;
    padding: 0 12px;
    min-height: 31px;
    font-size: 12px;
    line-height: 31px;
    color: #F03040;

    span {
        color: #3366FF;
    }
}

.scrollContent {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 650px;
}

.sub_box {
    padding: 0 8px 12px 8px;
    margin-bottom: 12px;
    border-bottom: 1px solid #BABABA;


    .sub_text {
        font-size: 12px;
        color: #1f1f1f;
    }

    .sub_list {
        color: #1f1f1f;
        font-size: 12px;
        display: flex;
        margin-top: 8px;

        >div:first-of-type {
            width: 130px;
        }
    }

}

.subconfig_box {
    padding: 0 8px 12px 8px;
    margin-bottom: 12px;
    // border-bottom: 1px solid #BABABA;

    .sub_text {
        font-size: 12px;
    }

    .sub_list {
        color: #1f1f1f;
        font-size: 12px;
        display: flex;
        margin-top: 8px;

        .using {
            color: #F03040;
            border: 1px solid #F03040;
            border-radius: 2px;
            padding: 0 5px;
            margin-right: 8px;
        }

        .binding {
            color: #3366ff;
            border: 1px solid #3366ff;
            border-radius: 2px;
            padding: 0 5px;
            margin-right: 8px;
        }

        .subscribeLevel {
            width: 150px;
        }
    }

    .sub_bottom_content {
        display: flex;
        margin-top: 12px;

        .sub_bottom_icon {
            margin-top: 3px;
        }

        .sub_bottom_title {
            margin-left: 5px;
            font-size: 12px;
        }

    }

}

.assetNameExpired {
    line-height: 46px;
    background: #e82222;
    font-size: 24px;
    color: #1f1f1f;
    padding-left: 9px;
    display: flex;
    align-items: center;
}

.model_section {
    // padding: 0 8px 12px 8px;
    margin-bottom: 12px;
    border-bottom: 1px solid #BABABA;
}

.contact_section_item {
    display: flex;
    color: #686868;
    font-size: 14;
    font-weight: 500;
    align-items: center;
    margin-bottom: 4px;
 
    .section_icon {
        background: url("../../../../../../assets/img/icon_star.png") no-repeat center;
        width: 10px;
        height: 10px; 
    }
}

.row_header_title {
    display: flex;
    font-size: 14px;
    color: #686868;
    align-items: center;  
    .section_icon {
        background: url("../../../../../../assets/img/icon_star.png") no-repeat center;
        width: 10px;
        height: 10px;
    }
}


.col_content {
    background-color: #3366FF;
}

//输入框相关
.router_model_input {
    border: 1px solid #BABABA !important;
    width: 470px;


    .ant-input {
        border-bottom: none !important;
    }
}

.router_model_input:hover {
    background: #EBEBEB;
    border: 1px solid #9F9F9F !important;
}

.router_model_input:focus-within {
    border-bottom: 2px solid #3366FF !important;
    border-color: #BABABA;
    box-shadow: none;
}

//输入框相关
.ip_model_input {
    border: 1px solid #BABABA !important;
    width: 140px;

    .ant-input {
        border-bottom: none !important;
    }
}

.ip_model_input:hover {
    background: #EBEBEB;
    border: 1px solid #9F9F9F !important;
}

.ip_model_input:focus-within {
    border-bottom: 2px solid #3366FF !important;
    border-color: #BABABA;
    box-shadow: none;
}

.ip_input_content {
    display: flex;

    .ip_separation {
        margin: 0 5px;
    }
}


//设置服务方式
.introduce_box {
    display: flex;
    color: #1f1f1f;
    .introduce_item {
        width: 340px;
        height: 53px;
        border: 1px solid #BABABA;
        position: relative;
        padding-top: 8px;
        cursor: pointer;
    }
    .introduce_item_selected {
        width: 340px;
        height: 53px;
        border: 1px solid #3366FF;
        position: relative;
        color: #3366FF;
        padding-top: 8px;
        cursor: pointer;
    }

   
    .right{
        margin-left: 64px;
    }
 
    .introduce_message_box{
        margin-left: 8px;
    }
    .introduce_title{
        font-size: 12px;
        padding: 0 16px;
        font-family: ABBvoiceCNSG-Bold;
    }
    .introduce_text{
        font-size: 12px;
        transform:scale(0.8);
        // padding: 0 16px;
        margin-left: -17px;
    }
}

.section_title {
    margin-top: 24px;
    color: #1f1f1f;
    font-size: 16px;
    font-family: ABBvoiceCNSG-Bold;
}

.contactPersonBox {
    display: flex;
    font-size: 14px;
    margin-top: 12px;

    .left {
        width: 300px;

        .instructions {
            height: 36px;
            color: #1f1f1f;
            font-size: 14px;
            margin-bottom: 16px;
        }

        .input_content {

            .item_title {
                color: #686868;
            }

            .input_box {
                display: flex;


                .input_left {
                    width: 152px;
                }

                .input_right {
                    margin-left: 10px;
                    width: 288px;
                }
            }
        }


    }

    .center {
        width: 80px;
        margin: 0 30px;
        display: flex;
        position: relative;

        .line {
            background-color: #BABABA;
            width: 1px;
            height: 100%;
            margin: auto;
        }

        .center_button_box {

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            display: flex;

            .center_button {

                margin: auto;
                background-color: #FFF;


                .center_button_icon {

                    width: 48px;
                    height: 48px;
                    background: url(".../../../../../../../../assets/img/icon_filling_contact.png") no-repeat center;
                    background-size: 100%;
                    margin: auto;
                    margin-top: 5px;
                }
            }
        }
    }

    .right {
        width: 230;
        color: #686868;

        .instructions {
            height: 36px;
            color: #1f1f1f;
            font-size: 14px;
            margin-bottom: 16px;
        }

        .input_content {
            margin-top: 16px;
            DIS
            .name_phone {
            
                height: 32px;
                line-height: 32px;
                margin-top: 7px;
            }
            margin-bottom: 20px;
        }
        .input_content1 {
            margin-top: 30px;

            .name_phone {
               
                height: 32px;
                line-height: 32px;
                margin-top: 7px;
            }
            margin-bottom: 20px;
        }
    }
}

.external_default {
    width: 14px;
    height: 14px;
    border: 1px solid #BABABA;
    margin-top: 9px;
    margin-left: 3px;
    border-radius: 7px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.external_selected {
    width: 14px;
    height: 14px;
    border: 5px solid #3366FF;
    margin-top: 9px;
    margin-left: 3px;
    border-radius: 7px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.service_record {
    margin-top: 8px;
    color: #1f1f1f;
    font-size: 12px;

}

.line_wrapper {
    display: flex;
    justify-content: space-between;

}

.check_button {
    margin-top: 3px;
}

.remind_sub_text {
    font-size: 14px;
    color: #1f1f1f;
}

.remind_center_box {
    margin-top: 12px;
    font-size: 14px;
    color: #1f1f1f;

    .text_blue {
        color: #3366FF;
    }
}

.remind_item {
    margin-top: 8px;
    font-size: 16px;
    color: #1f1f1f;

}

//基础版
.subscribe_type1 {
    border: 1px solid #3366FF;
    color: #3366FF;
    margin-left: 16px;
    font-size: 10px;
    line-height: 16px;
    width: 56px;
    text-align: center;
}

//入门版
.subscribe_type2 {
    border: 1px solid #FFA200;
    color: #FFA200;
    margin-left: 16px;
    font-size: 10px;
    line-height: 16px;
    width: 56px;
    text-align: center;
}

//标准版
.subscribe_type3 {
    border: 1px solid #0CA919;
    color: #0CA919;
    margin-left: 16px;
    font-size: 10px;
    line-height: 16px;
    width: 56px;
    text-align: center;
}

//高级版
.subscribe_type4 {
    border: 1px solid #ED5739;
    color: #ED5739;
    margin-left: 16px;
    font-size: 10px;
    line-height: 16px;
    width: 56px;
    text-align: center;
}

//专家服务
.subscribe_service {
    border: 1px solid #1f1f1f;
    color: #1f1f1f;
    margin-left: 16px;
    font-size: 10px;
    line-height: 16px;
    width: 56px;
    text-align: center;
}

.positionTitle{
    width: 100%;
    position: absolute;
    top: 58px;
    background: #fff;
    left: 12px;
    font-size: 12px;
    display: flex;
    align-items: center;
    
}
 